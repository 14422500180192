/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          //console.log('ready');

          //toggle accordion content
        $('[data-action-toggle]').click(function (e) {
          e.stopImmediatePropagation();
          var $target = $(this).attr('data-action-toggle'),
              textOpen = $(this).attr('data-text-open'),
              textClose = $(this).attr('data-text-close');


          $(this).toggleClass('_active').parents('section').find($target).slideToggle();

          if($(this).find('span').text() !== textOpen) {
            $(this).find('span').text(textOpen);
          } else {
            $(this).find('span').text(textClose);
          }

        });

          //footer
        if($(window).width() < 768) {
          $('.content-info .widget .menu-item-has-children > a').click(function (e) {
            e.preventDefault();

            $(this).parent().toggleClass('active').find('> ul').slideToggle();
          });

        } else {
          $('.content-info .widget .menu-item-has-children > a').click(function (e) {
            e.preventDefault();
          });
        }

          //mobile menu open close

          $('.menu-trigger').click(function () {
            $('body').toggleClass('menu-open');

            if( !$('body').hasClass('menu-open') && $('body').hasClass('search-active')) {
              $('body').removeClass('search-active');
            }
          });


          //mobile menu
          function mobileMenu() {
            $('.nav-primary .menu-item-has-children').click(function () {
              var   menuTitle = $(this).find('>a').text(),
                    $subMenuEl = $(this).find('>ul').html(),
                    mobileStageMenuHtml =   '<div class="menu-stage">' +
                                                '<span class="back-top">'+menuTitle+'</span>' +
                                                '<ul class="menu">'+$subMenuEl+'</ul>' +
                                            '</div>';


              $('nav.nav-primary').append(mobileStageMenuHtml);
              setTimeout(function () {
                $('nav.nav-primary').addClass('sub-open');
              }, 10);
            });

            $('body').on('click','.menu-stage .back-top', function () {
              $('nav.nav-primary').removeClass('sub-open');
              setTimeout(function () {
                $('nav.nav-primary .menu-stage').remove();
              }, 320);
            });
          }

          if(Modernizr.touchevents && $(window).width() < 768) {
            mobileMenu();
          }



          //search trigger
        $('.search-btn').click(function () {
          $('body').toggleClass('search-active');

          setTimeout(function () {
            $('.proinput input.orig').focus().css('background','red');
          },800);

        });

          $('.mobile-search-btn').click(function () {
            $('body').toggleClass('menu-open search-active');


            setTimeout(function () {
              $('.proinput input.orig').focus().css('background','red');
            },800);
          });


          //language switcher
        $('.language-switch-mobile > li > a').click(function (e) {
          e.preventDefault();
          $('.language-switch-mobile').addClass('_active');
        });
        $('.back-languages').click(function (e) {
          $('.language-switch-mobile').removeClass('_active');
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
          //console.log('on load');
      },
      resize:function () {
        //JS on window resize
          //console.log('window resized');
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'single_cpt_product': {
      init: function() {
        // JavaScript to be fired on the about us page


        function quickMenuMobile() {
          if($(window).width() < 992) {
            $('body')
            .on('click','.pdp-quickmenu .mobile-header strong',function (e) {
              e.stopImmediatePropagation();
              $('.pdp-quickmenu').toggleClass('active');
            })
            .on('click', '.pdp-quickmenu ul li', function () {
              $('.pdp-quickmenu').removeClass('active');
            });
          } else {
            $('body').off('click','.pdp-quickmenu .mobile-header strong');
          }
        }

        quickMenuMobile();
      },
      resize:function () {
        //JS on window resize

        function quickMenuMobile() {
          if($(window).width() < 992) {
            $('body')
            .on('click','.pdp-quickmenu .mobile-header strong',function (e) {
              e.stopImmediatePropagation();
              $('.pdp-quickmenu').toggleClass('active');
            })
            .on('click', '.pdp-quickmenu ul li', function () {
              $('.pdp-quickmenu').removeClass('active');
            });
          } else {
            $('body').off('click','.pdp-quickmenu .mobile-header strong');
          }
        }

        quickMenuMobile();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
    resizeEvents:function () {
        //fire on resize
        UTIL.fire('common','resize');
        UTIL.fire('single_cpt_product','resize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  window.addEventListener('resize', UTIL.resizeEvents);


})(jQuery); // Fully reference jQuery after this point.

function randomId() {
    return "id-" + Math.floor((1 + Math.random()) * 0x1000000)
        .toString(16)
        .substring(1);
}

jQuery(function() {

    document.querySelectorAll("input,textarea").forEach(function(input) {
        input.classList.toggle("changed", input.value.length > 0);
    });

    jQuery("body").on("input", "input,textarea", function(event) {
        var input = event.target;
        input.classList.toggle("changed", input.value.length > 0);
    });

    jQuery("body").on("focus", "input,textarea", function(event) {
        var input = event.target;

        if(!input.id) {
            input.id = randomId();
        }

        if(!input.labels.length) {
            var label = document.createElement("label");
            label.classList.add("flyout");
            label.htmlFor = input.id;
            label.textContent = input.placeholder;
            input.parentNode.insertBefore(
                label,
                input.nextSibling
            );
        }

        var label = input.labels[0];

    });
});

